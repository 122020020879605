.contact_links_newsletter a {
  color: #f7f7f7;
}

.tel__footer {
  display: flex;
  width: 100%;
}
.tel__footer span {
  display: flex;
  width: 100%;
}
.footer__container {
  width: 100%;
  position: relative;
  bottom: 0;
  margin: 0;
  overflow: hidden;
  /* background-color: var(--background-color); */
}

.footer_wave__container {
  width: 100%;
  background-color: transparent;
  display: flex;
}

.footer__content {
  background: linear-gradient(180deg, #040daf, var(--secondary-color));
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  flex-wrap: nowrap;
  padding: 30px 0;
}

.footer__content_top {
  display: flex;
  width: 90%;
  justify-content: space-around;
  align-items: flex-start;
  padding: 10px 0;
  min-height: 250px;
  gap: 3.5rem;
}

.footer_section {
  flex: 1 1;
  max-width: 300px;
  /* min-width: 250px; */
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.footer_section div {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer_social {
  display: block;
}

.footer_social .brand_logo {
  width: 60%;
  min-width: 270px;
  height: 94px;
}

.footer_social .brand_logo img {
  width: 107%;
  height: 93%;
  object-fit: cover;
}

.footer_social .social-media__links {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer_title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #f7f7f7;
  margin: 0;
  margin-bottom: 1.5rem;
}

.footer_info {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.footer_info .info_list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: white;
}

.footer_newsletter {
  align-items: flex-start !important;
}

.social-media__brandname {
  color: white;
}

@media (max-width: 900px) {
  .footer__container {
    margin-top: 5rem;
  }
  .footer__content_top {
    flex-direction: column;
    align-items: center;
  }

  .footer_section {
    align-items: center !important;
    max-width: none;
  }

  .footer_info .info_list {
    align-items: center;
  }
}
