.welcome-text {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  line-height: 25px;
}

.info-contact img {
  margin-left: 20px;
}

.principal-landing {
  margin-top: 100px;
}

.landing-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 230px;
}

.button-send-register {
  height: 40px;
  background-color: #72dd6f;
  color: #ffffff;
  font-size: 0.85rem;
  font-weight: bold;
  letter-spacing: 0px;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 3vh;
}

.container-text-landing {
  display: flex;
  margin-left: 130px;
  line-height: 10px;
}
.container-text-landing h2 {
  width: 50%;
  margin-top: 40px;
  justify-content: space-around;
  font-size: 30px;
  flex-direction: row;
  position: relative;
  top: 60px;
  text-align: center;
}

.container-img-landing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-left: -40%;
  margin-top: 5%;
}

.form-landing {
  width: 500px;
  background: #f8f9fd;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  height: 750px;
  margin-top: -730px;
}

.form-landing__inputs {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container-text-landing-end {
  display: flex;
  justify-content: center;
  margin-right: 40%;
  line-height: 28px;
}

.container-text-landing-end p {
  width: 40%;
  margin-top: 40px;
  justify-content: space-around;
  font-size: 20px;
  flex-direction: row;
  position: relative;
  top: 20px;
  left: 35px;
  text-align: center;
}

@media (max-width: 1500px) {
  .container-text-landing {
    display: flex;
    margin-left: -20px;
    line-height: 10px;
  }
  .container-img-landing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: -53%;
    margin-top: 5%;
  }

  .container-text-landing-end {
    display: flex;
    justify-content: center;
    margin-left: -15%;
    line-height: 28px;
  }

  .form-landing {
    margin-right: -10%;
  }
  .form-landing {
    background: #f8f9fd;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: height 2s ease;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
    height: 750px;
    margin-top: -680px;
  }
}

@media (max-width: 1200px) {
  .container-text-landing p {
    font-size: 20px;
  }
  .container-text-landing h2 {
    font-size: 20px;
  }
  .container-text-landing-end {
    margin-left: -20%;
  }
}

@media (max-width: 1100px) {
  .container-text-landing p {
    font-size: 18px;
  }
  .container-text-landing h2 {
    font-size: 18px;
  }
  .container-text-landing-end {
    margin-left: -25%;
  }
  .container-text-landing-end p{
font-size: 18px;

  }
}

@media (max-width: 1080px) {
  .container-img-landing img {
    display: none;
  }
  .container-text-landing h2 {
    display: none;
  }
  .container-text-landing p {
    display: none;
  }
  .container-text-landing-end {
    display: none;
  }

  .landing-container {
    display: flex;
    justify-content: center;
    margin-top: 680px;
    margin-right: 70px;
    margin-left: 50px;
  }
}

@media (max-width: 380px) {

  .form-landing {
    height: 800px;
  }
}


@media (max-width: 340px) {

  .form-landing {
    height: 870px;
  }
}