.carousel-inner {
  display: flex;
  height: 600px;
}
.carousel.slide {
  margin-top: 4rem;
}
.content-img-1 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 70px;
}
.content-img-2 {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: end;
}
.img-carousel-1 {
  position: relative;
  width: 100%;
  margin-top: -200px;
}
.img-carousel-2 {
  width: 100%;
  margin-top: -350px;
}

.img-carousel-wanak {
  display: flex;
  width: 20%;
  margin-top: -50px;
}

.container-gif {
  width: 100%;
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.header-colors img {
  width: 100%;
}
.carousel-caption {
  display: flex;
  height: 100%;
}
.subtitle-img-1 {
  display: flex;
  width: 100%;
  font-size: 60px;
  margin-bottom: 30px;
  flex-direction: column;
  align-items: flex-start;
  -webkit-text-stroke: 1px;
  text-shadow: 2px 2px 2px rgb(79, 74, 74);
}
.btn-img-1 {
  display: flex;
  width: 8%;
  height: 50px;
  border-color: #0606f9;
  color: #0606f9;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  margin-bottom: 50px;
  outline: none;
  box-shadow: #0606f9;
}

.btn-img-1:hover {
  border: #0606f9;
}

.text-img-1-end {
  display: flex;
  width: 100%;
  font-size: 25px;
  flex-direction: column;
  align-items: flex-start;
  text-align: justify;
  line-height: 35px;
}

.container-text-end-1 {
  width: 40%;
}

.subtitle-img-2 {
  width: 40%;
  font-size: 60px;
  float: right;
  -webkit-text-stroke: 1px;
  text-shadow: 2px 2px 2px rgb(79, 74, 74);
}

.subtitle-img-2 p {
  text-align: start;
  font-size: 50px;
}

.subtitle-img-2 strong {
  padding-right: 2rem;
}

.btn-img-2 {
  display: flex;
  width: 10%;
  height: 50px;
  border-color: #0606f9;
  color: #0606f9;
  border-radius: 15px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  margin-bottom: 100px;
  outline: none;
  margin-left: 90%;
  box-shadow: #0606f9;
}

.btn-img-2:hover {
  border: #0606f9;
}
.text-img-2-end {
  font-size: 30px;
  line-height: 30px;
}
.suscribete__btn{
  width: 40%;
}
.subtitle__suscribe {
  text-align: end;
  display: flex;
  flex-direction: column;
  align-items: end;
}
.container-text-end-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 40%;
  margin-left: 65%;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  padding: 0;
  list-style: none;
}

@media (max-width: 1700px) {
  .img-carousel-1 {
    margin-top: -100px;
  }
  .img-carousel-2 {
    margin-top: -100px;
  }
  .carousel-inner {
    margin-bottom: -30px;
  }
  .btn-img-2 {
    width: 10%;
  }
  .text-img-2-end {    
    margin-left: -5px;
    width: 40%;
    text-align: start;
  }
}

@media (max-width: 1500px) {
  .img-carousel-1 {
    margin-top: -30px;
  }
  .subtitle-img-1 p {
    font-size: 60px;
  }
  .subtitle-img-1 {
    margin-top: 10px;
  }
  .img-carousel-2 {
    margin-top: -30px;
  }
  .btn-img-2 {
    width: 13%;
  }
  .text-img-2-end {
    font-size: 25px;
  }
  .content-img-1 {
    margin-bottom: -15px;
  }
}

@media (max-width: 1259px) {
  .carousel-inner {
    height: 550px;
  }
  .subtitle-img-1 {
    font-size: 60px;
  }
  .subtitle-img-1 p {
    font-size: 40px;
  }
  .text-img-1-end {
    font-size: 15px;
  }
  .btn-img-1 {
    width: 10%;
  }
  .content-img-1 {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
  }
  .subtitle-img-2 {
    width: 100%;
  }
  .text-img-2-end {
    font-size: 20px;
  }
  .btn-img-2 {
    width: 13%;
    margin-top: -45px;
    position: relative;
    top: 35px;
  }
}

@media (max-width: 1160px) {
  .carousel-inner {
    height: 510px;
  }
  .text-img-1-end {
    font-size: 15px;
    line-height: 20px;
  }
  .content-img-1 {
    margin-bottom: -35px;
  }
  .content-img-2 {
    margin-bottom: -35px;
  }
  .text-img-2-end {
    font-size: 18px;
  }
}

@media (max-width: 1090px) {
  .subtitle-img-1 {
    font-size: 55px;
  }
  .text-img-1-end {
    font-size: 12px;
    line-height: 20px;
  }
  .content-img-1 {
    margin-bottom: 15px;
  }
  .content-img-2 {
    margin-bottom: -50px;
  }
  .btn-img-2 {
    font-size: 15px;
  }
}

@media (max-width: 950px) {
  .carousel-inner {
    height: 400px;
  }
  .subtitle-img-1 {
    font-size: 50px;
  }
  .text-img-1-end {
    font-size: 12px;
    line-height: 15px;
  }
  .content-img-1 {
    margin-bottom: -50px;
  }
  .carousel-indicators {
    visibility: hidden;
  }
  .suscribete__btn {
    
    width: 100%;
  }
  .text-img-2-end {
    font-size: 15px;
    line-height: 15px;
    margin-left: -250px;
    margin-bottom: 20px;
  }
  .subtitle-img-2 {
    font-size: 50px;
  }
  .subtitle-img-2 p {
    font-size: 40px;
  }
}

@media (max-width: 900px) {
  .subtitle-img-1 {
    font-size: 40px;
    margin-bottom: -10px;
  }
  .subtitle-img-1 p {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .content-img-1 {
    margin-bottom: -35px;
  }
  .subtitle-img-2 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .subtitle-img-2 p {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .btn-img-2 {
    font-size: 15px;
    width: 20%;
    height: 45px;
  }
}

@media (max-width: 780px) {
  .carousel-inner {
    height: 290px;
  }
  .subtitle-img-1 {
    font-size: 35px;
    /* margin-bottom: -40px; */
  }
  .container-text-end-1 {
    width: 100%;
  }
  .text-img-1-end {
    font-size: 9px;
    line-height: 15px;
    margin-bottom: -20px;
  }
  .text-img-2-end {
    font-size: 9px;
    line-height: 15px;
    position: relative;
    left: -30px;
  }
  .btn-img-1 {
    width: 10%;
    height: 25px;
    border-radius: 8px;
    font-size: 10px;
    margin-bottom: 16px;
  }
  .content-img-1 {
    margin-bottom: -15px;
  }
  .subtitle-img-2 {
    font-size: 35px;
    margin-bottom: -40px;
  }
  .subtitle-img-2 p {
    font-size: 18px;
  }

  .btn-img-2 {
    width: 20%;
    height: 40px;
    border-radius: 15px;
    font-size: 13px;
    position: relative;
    top: 80px;
    left: -50px;
  }
}

@media (max-width: 630px) {
  .subtitle-img-1 {
    font-size: 35px;
  }
  .subtitle-img-1 p {
    font-size: 28px;
  }
}

@media (max-width: 589px) {
  .carousel-inner {
    height: 260px;
  }
  .subtitle-img-1 {
    font-size: 30px;
    margin-bottom: 3px;
  }
  .subtitle-img-1 p {
    font-size: 20px;
  }
  .subtitle-img-2 {
    font-size: 25px;
    margin-bottom: -60px;
  }
  .btn-img-2 {
    font-size: 12px;
    width: 15%;
    height: 35px;
    border-radius: 10px;
  }
}

/* DISEÑO TELEFONO */

@media (max-width: 540px) {
  .carousel-inner {
    height: 250px;
  }
  .carousel-control-next {
    margin-top: 120px;
  }
  .carousel-control-prev {
    margin-top: 120px;
  }
  .subtitle-img-1 {
    font-size: 25px;
    margin-bottom: -25px;
  }
  .subtitle-img-2 {
    font-size: 20px;
    margin-top: -20px;
  }
  .img-carousel-1 {
    margin-top: 40px;
  }
  .img-carousel-2 {
    margin-top: 40px;
  }
  .container-text-end-1 {
    display: flex;
    width: 70%;
  }
  /* .container-text-end-2{
    display:flex;
    width: 70%;
      } */
  .btn-img-1 {
    position: relative;
    top: 10px;
    width: 15%;
  }
  .btn-img-2 {
    position: relative;
    width: 15%;
    height: 30px;
    font-size: 7px;
    left: -28px;
    border-radius: 10px;
  }
  .content-img-1 {
    width: 100%;
  }

  .container-gif {
    width: 100%;
    margin-top: 80px;
  }

  .btn-img-1 {
    width: 25%;
    height: 30px;
  }
}

@media (max-width: 440px) {
  .carousel-control-next {
    margin-top: 110px;
  }
  .carousel-control-prev {
    margin-top: 110px;
  }
  .btn-img-2 {
    display: flex;
    font-size: 8px;
    width: 25%;
    height: 30px;
    top: 100px;
    border-radius: 8px;
  }
  .text-img-1-end {
    visibility: hidden;
  }
  .text-img-2-end {
    visibility: hidden;
  }
  .subtitle-img-1 p {
    font-size: 15px;
  }
}
