.store-company__form__inputs-container {
   width: 100%;
   display: flex;
   flex-direction: column;
   gap: 2.5rem;
   margin-bottom: 2rem;
}

.company_form_inputs{
    display: flex;
    flex-wrap: nowrap;
    grid-gap: 1rem;
    gap: 1rem;
    width: 100%;
    min-width: 300px;
    flex-direction: row;

}


.company_form_inputs--small{
    min-width: 150px !important;
}

.company_form_inputs--small label{

    min-width: 70px !important;

}

.company_form_inputs label{
    min-width: 200px;
}

.flex_6{
    flex: 6;
    min-width: 350px !important;
}
.flex_4{
    flex: 4;
}
.flex_3{
    flex: 3;
}
.flex_2{
    flex: 2;
}


@media(max-width: 850px){
    .company_form_inputs{
        display: flex;
        flex-direction: column;
    }
    .company_form_inputs select{
        /* margin-top: 0; */
        width: 100%;
        background: #fff 0 0 no-repeat padding-box;
        border: var(--border-input);
        border-color: #bbbaba;
        border-radius: 10px;
        font-size: .85em;
        font-weight: 500;
        letter-spacing: 0;
        color: #08254a;
        padding: 0 0.5rem;
        outline: none;
        font-family: "Montserrat";
    }
    .select-regiones{
        height:40px; ;
    }

}

@media (max-width: 650px){
    .flex_6{
        min-width: 350px !important;
    }

}
@media (max-width: 450px){
    .company_form_inputs{
        min-width: 10px ;
    }
    .flex_6{
        min-width: 200px !important;
    }
}