/* .modal-tenders {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5200;
  background-color: rgba(0, 0, 0, 0.8);
} */

.modal-tenders__container {
  z-index: 6000;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: #5c5c5c;
  padding: 0 1rem;
}

.modal_tenders_info_container * {
  margin-bottom: 1rem;
}

.modal-tenders__title {
  font-size: 1.14em;
  font-weight: bold;
  margin: 10px auto;
  margin-left: 0;
}

.modal-tenders__code {
  font-size: 1em;
  font-weight: 600;
  margin-top: 0px;
  margin-bottom: 15px;
  color: var(--secondary-color);
}

.modal-tenders__description {
  color: #5c5c5c;
  font-size: 0.9em;
  font-weight: 500;
  margin: 0px auto;
  text-align: justify;
  max-height: 20rem;
  overflow-y: auto;
  padding-right: 1rem;
  margin-bottom: 1rem;
  letter-spacing: 0.5px;
  text-align: initial;
  line-height: 19px;
}

.modal-tenders__buyer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.modal-tenders__buyer p {
  display: inline;
  color: #5c5c5c;
  font-size: 0.9em;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
}

.modal-tenders__buyer span {
  font-weight: 500;
  margin: 0;
  margin-left: 10px;
  font-size: 0.9em;
}

.modal-tenders__button {
  background-color: rgb(0, 101, 255);
  border: 1px solid transparent;
  height: 40px;
  border-radius: var(--border-radius);
  width: 100%;
  color: #fff;
  font-size: 1rem;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}

@media (max-width: 500px) {
  .modal-tenders__container {
    max-height: none;
    height: 80vh;
  }

  .modal-tenders__container button {
    justify-self: flex-end;
  }
}
