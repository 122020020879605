/* NUEVO DISEÑO */
.explain__title__second {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 4%;
}
.title__second__main {
  display: flex;
  width: 390px;
  height: 95px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 50px 0px 0px 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #1251ae;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 61px;
}

.container__step1 {
  display: flex;
  width: 340px;
  height: 162px;
  background: #fbfbfc;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container__step1 p{
  width: 70%;
  padding-left: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 30px;
  text-transform: lowercase;
  color: #2B2C34;

}

/* NUEVO DISEÑO */
.btn-cotizador-products {
  display: flex;
  flex-direction: row;
  width: 178px;
  height: 48px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  padding: 16px 24px;
  background: #ffffff;
  border: 1px solid #00367b;
  border-radius: 10px;
  font-family: "Inter", sans-serif;
}

.title-security-products {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  color: #00367b;
  justify-content: center;
}
.text-security-products {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
  text-align: center;
}
.text-our-products {
  width: 100%;
  margin-bottom: 2%;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.container-img-our-products {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: row-reverse;

  /* margin-left: -%; */
}

.cards-our-products {
  display: flex;
  justify-content: space-around;
  width: 261px;
  height: 342px;
  flex-direction: column;
  margin: 3%;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 2%;
}
.products-img {
  position: relative;
  left: -12.3%;
  top: -40px;
}

.collaborators__container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: var(--background-color);
}

.collaborators__top-size {
  width: 100%;
  flex: 0.5;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  /* margin-top: 10px;
   margin-bottom: 30px; */
  background-color: #fff;
  padding-bottom: 3rem;
  padding-top: 1rem;
}

.collaborators__title__main {
  color: var(--primary-color);
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  margin-bottom: -15px;
}

.collaborators__title__secondary {
  margin: 10px;
  color: #ffffff;
  font-size: 2rem;
  font-weight: 500;
}

/* Carrusel */

:root {
  --carrusel-image-width: 180px;
  --carrusel-image-margin: 40px;
  --number-of-companies: 15;
}

.collaborators__banner {
  width: 100%;
  display: flex;
  background-color: #fff;
  border: var(--border);
  border-radius: 12px;
  overflow-x: hidden;
  margin-top: 2%;
}

.collaborators__banner_track {
  width: calc(
    var(--carrusel-image-width) + (var(--carrusel-image-margin) * 2) *
      (2 * var(--number-of-companies))
  );
  background-color: #ffffff;
  display: flex;
  align-items: center;
}

.collaborators__banner_track img {
  width: var(--carrusel-image-width);
  height: 100px;
  object-fit: contain;
  margin: var(--carrusel-image-margin);
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  animation: scroll 60s linear infinite;
  filter: grayscale(100%);
}

.elipse{
  margin-top: 8vh;
  margin-bottom: 4vh;
}

@media (max-width: 1100px){
  .title__second__main{
    font-size: 40px;
    width: 420px;
  }
  .elipse{
 margin-left: 10%;
 margin-right: 10%;
  }
  .elipse img{
    width: 100%;
  }
} 

@media (max-width: 800px){
  .title__second__main{
    font-size: 35px;
    width: 390px;
  }
}

@media (max-width: 600px){
  .title__second__main{
    font-size: 30px;
    width: 350px;
    height: 80px;
  }
  .elipse{
    width: 95%;
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(
      calc(
        (var(--carrusel-image-width) + (var(--carrusel-image-margin)) * 2) *
          (-1 * var(--number-of-companies))
      )
    );
  }
}

/* /////////////////////////*/

@media (max-width: 450px) {
  .collaborators__title__main {
    font-size: var(--font-size-title-responsive);
  }

  .collaborators__bottom-size {
    background-size: cover;
  }

  .collaborators__banner {
    overflow-y: hidden;
  }

  .collaborators__banner img {
    scroll-snap-align: center;
    /* margin-left: 10px;
      margin-right: 10px; */
  }
}

@media (max-width: 750px) {
  .collaborators__title__main {
    padding: 0 2rem;
  }

  :root {
    --carrusel-image-width: 130px;
    --carrusel-image-margin: 20px;
  }
}

@media (max-width: 1700px) {
  .products-img {
    position: relative;
    left: -75px;
    top: -40px;
  }
}

@media (max-width: 1200px) {
  .products-img {
    display: none;
  }
  .container-img-our-products {
    margin-left: 0%;
  }
  .collaborators__container {
    margin-top: -40px;
  }
}
