.button-singIn {
  width: 123px;
  height: 40px;
  background-color: #fff;
  color: #0065ff;
  border-color: #0065ff;
  border-radius: 10px;
}
.button-singIn:hover {
  width: 123px;
  height: 40px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  color: #fff;
  border: none;
  border-radius: 10px;
}

.button-register-header {
  width: 123px;
  height: 40px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  color: #fff;
  border: none;
  border-radius: 10px;
}
.button-register-header:hover {
  background: linear-gradient(260deg, #0606f9 0%, #0099ff 100%);
}

.header__container {
  position: relative;
  width: 90%;
  height: 100%;
  background-color: transparent;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  z-index: 5000;
  transition: height 0.3s ease;
}
.header {
  position: fixed;
  top: 0;
  z-index: 5000;
  width: 100vw;
  height: 9vh;
  font-weight: bold;
  background-color: #fff;
  transition: box-shadow 0.2s ease-in, height 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.header__container--scrolled {
  box-shadow: -1px 2px 10px #d7defa;
  border: none;
  height: 7vh;
  /* padding-bottom: 5px; */
}

.header__container--scrolled .header__container {
  height: 9vh !important;
}

.header__logo {
  background-image: url("../../assets/images/LOGO-INGE-SERVFINANCIEROS-EDDEF2022.png");
  background-repeat: no-repeat;
  background-position: left;
  background-size: contain;
  cursor: pointer;
  height: 68%;
  margin-top: 9px;
  margin-bottom: 9px;
  outline: none;
  width: 195px;
}
.header__nav {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav__link-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* grid-gap: 0.2rem; */
  gap: 2rem;
  margin-bottom: 5px;
  margin-left: 3%;
}

.nav__link {
  list-style: none;
  text-transform: none;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  position: relative;
}

.link {
  color: black;
  text-decoration: none;
  font-weight: 500;
  position: relative;
  display: block;
  font-size: 0.94rem;
  transition: 0.3s;
}

.link:hover {
  color: #0606f9;
}
.active {
  color: #0606f9;
  cursor: default;
}
.active svg {
  -webkit-filter: grayscale(90%);
  filter: grayscale(90%);
}
.active .text-slidebar {
  color: gray;
  font-weight: 600;
}
.slide_bar_hover {
  position: relative;
  padding-left: 1rem;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 100px;
  transition: color 0.3s;
  transform: perspective(1px) translateZ(0);
}
.slide_bar_hover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: #c9c9c9;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  transform: scaleX(0);
  transform-origin: 0 50%;
  transition: transform 0.6s ease-out;
}
.slide_bar_hover:hover::after {
  transform-origin: left;
  transform: scaleX(1);
}
.slide_bar_hover:hover {
  color: white;
}
.slide_bar_hover:hover:before {
  transform: scaleX(1);
}

.link::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  background: linear-gradient(260deg, #0606f9 0%, #0099ff 100%);
  top: 20px;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}

.link:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

.active::before {
  width: 100%;
  height: 0px;
  background-color: white;
}
.link-sobre {
  color: black;
  text-decoration: none;
  width: 100%;
  display: flex;
  font-weight: 400;
}

.nav__link .link:active {
  color: var(--primary-color);
}

/* 
.nav__link:hover {
  font-weight: 600;
  background-color: #bad4fe;
  color: #0065ff;
  font-weight: bold;
} */

.nav__link__dropdownMenu {
  position: absolute;
  background-color: white;
  list-style-type: none;
  top: 20px;
  left: 0;
  width: max-content;
  max-width: 300px;
  border-radius: 5%;
  box-shadow: -1px 2px 10px #d7defa;
  overflow: hidden;
  max-height: 0;
  padding: 0;
}

.dropdown_menu_link .link_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dropdown_menu_link_description_container .description {
  text-align: justify;
  text-justify: inter-word;
}

.dropdown_menu_link_description_container {
  max-height: 1px;
  overflow-y: hidden;
  margin: 0;
}

.description_open {
  transition: max-height 1.4s ease-in-out;
  max-height: 1000px;
}

.nav__link__dropdownMenu li {
  border: none !important;
  border-radius: 0 !important;
}

.nav__link__dropdownMenu li:not(:last-child) {
  border-bottom: #0006421f 1px solid !important;
}

.nav__link__dropdownMenu li .link {
  color: #312f2f !important;
  font-weight: 500;
}

.nav__link__dropdownMenu li .link_hoverable:hover {
  color: var(--primary-color) !important;
}

.nav__link-container .nav__link:hover > ul {
  max-height: 10000px;
  transition: max-height 0.4s ease-in;
  padding: 10px;
}

/* .nav__link .link .active {
   background-color: #eef2fd;
} */

.nav__buttons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
}

.nav__menu {
  display: flex;
  height: 45%;
  justify-content: space-around;
  align-items: center;
  position: relative;
  background-color: #f8f9fd;
  outline: 1px solid #bad4fe;
  border: 0.8px solid #1a69df;
  border-radius: 25px;
  padding: 0 1rem;
  text-align: center;
}

.nav__menu__clientDisplay {
  display: flex;
  align-items: center;
  margin-right: 30px;
  gap: 0.5rem;
}

.nav__menu__client-name {
  font-size: 0.92rem;
  color: #0065ff;
}

.nav_menu_toggler {
  height: 40px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-faded);
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  margin: auto 10px;
  font-size: 0.92rem;
  color: #0065ff;
}

.nav__menu * {
  font-weight: 600;
}

.nav_menu_companyDisplay {
  color: var(--primary-color);
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.nav_menu_companyName {
  color: var(--primary-color);
  width: 100%;
  max-width: 250px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

/* .nav__menu__menu:focus ~ .nav__menu__container{
   height:235px;
} */

.nav__menu__container {
  position: absolute;
  top: 50px;
  right: 59px;
  border-radius: 5%;
  box-shadow: -1px 2px 10px #d7defa;
  width: 250px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.nav_menu_toggler:focus-within .nav__menu__container {
  max-height: 1500px;
}

.nav_menu_toggler:focus {
  pointer-events: none;
}

.nav_menu_toggler:focus .nav__menu__container {
  pointer-events: all;
}
.menu_toggler_icon {
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
}
.nav_menu_toggler:focus .menu_toggler_icon {
  transform: rotate(90deg);
}

/* .menu_open{
   max-height:235px;
} */

/* .nav__menu__container:focus ~ .nav__menu__container{
   height:235px !important;
} */

.nav__menu__container ul {
  background-color: #fff;
  padding: 7px;
  width: 100%;
  margin: 0;
  border-radius: 0 0 5% 5%;
}

.dropdown_menu_link {
  list-style: none;
  font-size: 0.9rem;
  margin: 5px;
  color: #707070 !important;
  font-weight: 400;
  padding: 0.5rem;
  border: 2px solid #bad4fe;
  border-radius: var(--border-radius);
  margin-left: 0;
  width: 100%;
}

/* .nav__menu__container li:not(:last-child) {
   /* border-bottom: 1px solid rgba(204, 198, 198, 0.521); */
/* padding-bottom: 10px; */
/* background-color: red; 
} */

.nav__menu__container li:last-child {
  background-color: #bad4fe;
  color: var(--primary-color);
  font-weight: 700;
}

.nav__menu__container li span {
  cursor: pointer;
}

.nav__menu__container li span:hover {
  cursor: pointer;
  color: #0065ff;
}

/* mobile section */

.header__container .header__container__icon {
  /* border: 2px solid var(--primary-color); */
  padding: 0.1rem 0.3rem;
  box-sizing: content-box;
  color: var(--primary-color);
  border-radius: var(--border-radius);
}

.header_left_container__mobile {
  display: flex;
  gap: 1.5rem;
  align-items: center;
}

.mobile-menu__icon-x {
  border: 1px solid #000642;
  border-radius: var(--border-radius);
  padding: 0.2rem 0.4rem;
  box-sizing: content-box;
  background: #004296;
}

.header__logo--mobile {
  width: 180px;
}

.mobile_menu_toggler:focus-within .header__mobile-menu {
  width: 350px !important;
}
.mobile_menu_toggler:focus-within .mobile_menu_toggler__hamburger,
.mobile_menu_toggler {
  pointer-events: none;
}

.mobile_menu_toggler:focus-within .header__mobile-menu {
  pointer-events: all;
}

.mobile_menu_toggler__hamburger {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 1;
  border: 0;
  background: transparent;
  border-radius: 0;
  height: 70px;
  width: 30px;
  cursor: pointer;
  pointer-events: auto;
  touch-action: manipulation;
  gap: 6px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.icon-bar {
  display: block;
  width: 100%;
  height: 4px;
  background: #0606f9;
  transition: 0.3s;
  border-radius: 3px;
}
.mobile_menu_toggler:focus-within .mobile_menu_toggler__hamburger {
  gap: 4px;
}
.mobile_menu_toggler:focus-within .icon-bar:nth-of-type(1) {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}
.mobile_menu_toggler:focus-within .icon-bar:nth-of-type(2) {
  opacity: 0;
}
.mobile_menu_toggler:focus-within .icon-bar:nth-of-type(3) {
  transform: translate3d(0, -8px, 0) rotate(-45deg);
}
/* Mobile Menu */
.header__mobile-menu {
  width: 0px;
  overflow: hidden;
  background-color: #f8f9fd;
  height: -moz-fit-content;
  height: fit-content;
  position: absolute;
  right: -38px;
  transition: width 0.5s ease-in-out;
  z-index: 9999;
  border-radius: 0.4rem;
  box-shadow: -3px 5px 20px 0px #8890b1;
}

.mobile_menu--open {
  width: 350px !important;
}

.mobile_menu_content_container {
  padding: 25px;
  margin: 0;
  width: 350px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.mobile_menu_icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
}

.mobile_menu_icon img {
  width: 100%;
}

.mobile-menu__nav__link-container,
.mobile_menu_logedUser {
  opacity: 1;
  width: 100%;
  margin: 0;
  padding: 0;
  /* width: 250px; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  /* transition: 0.7s ease-in-out; */
  gap: 20px;
}
.mobile_menu_logedUser {
  margin-bottom: 20px;
}

.mobile-menu__nav__link-container li {
  cursor: pointer;
}

.mobile-menu__nav__link-container li:hover {
  color: #000642 !important;
}

.nav_buttons--mobile {
  width: 100% !important;
}

.mobile-menu__icon {
  list-style-type: none;
  align-self: center;
  width: 30px;
  height: 30px;
}
.mobile-menu__icon svg {
  width: 100%;
  height: 30px;
  font-size: 2em;
}

.mobile__nav__link {
  border: none;
  width: 100%;
  background-color: white;
  list-style-type: none;
  color: var(--primary-color);
  font-weight: 600;
  font-size: 18px;
  white-space: nowrap;
  text-align: center;
  border: 2px solid white;
  border-radius: var(--border-radius);
  padding: 0.5rem;
}

.mobile__nav__link .link {
  width: 100%;
  display: flex;
  font-size: 0.9em;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: var(--secondary-color);
  cursor: pointer;
}

.mobile_menu_name {
  margin: 0;
}

.link .link_text,
.mobile_menu_name {
  max-width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--secondary-color);
}

.link_collapsable_icon_open {
  transform: rotate(90deg);
  transition: transform 0.2s ease;
}

.link_collapsable_icon_closed {
  transform: rotate(0deg);
  transition: transform 0.2s ease;
}

.mobile_dropdown {
  padding: 0;
  margin: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.mobile_dropdown--open {
  padding-top: 0.8em;
  max-height: 500px;
  transition: max-height 0.4s ease-in;
  /* , padding-top 0.2s; */
}

.mobile_dropdown--closed {
  padding-top: 0em;
  max-height: 0px;
  transition: max-height 0.3s ease, padding-top 0.5s;
}

.mobile_dropdown_link .link {
  color: #312f2f;
  font-weight: 500;
  font-size: 0.8em;
  background-color: aliceblue;
  padding: 10px;
  border-radius: var(--border-radius);
  justify-content: flex-start;
  gap: 8px;
}
/* .mobile_dropdown_link .exit_link{
   /* justify-content: space-between; */

.mobile_dropdown_link:hover .link {
  color: var(--primary-color);
}

.cartIcon_container {
  display: flex;
  justify-content: flex-end;
}
.cartIcon_container img {
  width: 80%;
  margin-top: 1px;
  position: relative;
  left: 16px;
}
.cart_indicator {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: 700;
  position: absolute;
  font-size: 0.7rem;
  top: -3px;
  right: -5px;
  align-content: center;
}

.container-items-slidebar,
.container-items-sliderbar-close {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
/* 
   Menu responsive 
   1. crearlo
   2. conectar url
   3. mostrar en Mq   
*/

.container-principal-sliderbar {
  position: fixed;
  background-position: right;
  left: 11px;
}

.container-principal-slidebar-open {
  position: fixed;
  display: flex;
  width: 213px;
  top: 92px;
  z-index: 1;
}

.container-principal-slidebar-close {
  position: fixed;
  display: flex;
  width: 72px;
  top: 92px;
  z-index: 1;
  /* transition: width 1s; */
  flex-direction: column;
}

.container-info-slidebar-open {
  display: flex;
  padding-bottom: 5vh;
  width: 100%;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  text-align: right;
  margin-left: -10px;
}

.container-info-slidebar-close {
  display: flex;
  /* height: 590px; */
  padding-bottom: 5vh;
  width: 100%;
  flex-direction: column;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-left: -15%;
}
.container-items-slidebar {
  display: flex;
  width: 100%;
  margin-top: -14px;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
.container-items-sliderbar-close {
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
}
a {
  display: flex;
  text-decoration: none;
}

.btn-slidebar-open {
  margin-top: 40px;
  margin-left: 1px;
  border: none;
  background: none;
}

.btn-slidebar-close {
  margin-top: 40px;
  margin-left: 14px;
  border: none;
  background: none;
}

.btn-back {
  position: relative;
  transform: translateZ(20px);
  transition: color 0.4s;

  display: flex;
  width: 100%;
  margin-top: 25px;
  border: none;
  background: none;
  flex-direction: column;
  align-items: center;
}
.btn-back::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.btn-back:hover {
  color: red;
  transition: color 0.6s;
}

.content-slidebar-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin: 5px;
  margin-left: 1rem;
  padding: 0.4rem 0px;
}
.container-items-close {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-slidebar {
  display: flex;
  width: 100%;
  color: #000000;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 0;
  margin-left: 0.5rem;
}

.container-contact-sliderbar {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: baseline;
}

.name-client-slidebar {
  width: 10%;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  margin-top: -5px;
}

.company-client-slidebar {
  color: #2b2c34;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  text-align: start;
}

.container-name-company {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 5%;
  /* left: 38%; */
  top: -5%;
}

@media (max-width: 1300px) {
  .cart_indicator {
    top: 15px;
    right: 30px;
  }
  .header__logo {
    height: 60%;
  }
}

@media (max-width: 957px) {
  .header {
    display: block;
  }
}

@media (max-width: 1600px) {
  .nav_menu_toggler {
    max-width: 250px;
  }
}

@media (max-width: 1450px) {
  .nav_menu_companyName {
    /* max-width: 64px; */
    display: none;
  }

  .nav_menu_toggler {
    gap: 0.5rem;
  }
}

@media (max-width: 450px) {
  .header__container {
    padding-right: 0;
  }
  /* .header__logo {
      width: 140px;
   } */
}
.contenedor_company {
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 20px;
  padding: 20px;
  max-width: 120rem;
  width: 67%;
  margin: 0 auto;
}
.high_form_register {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.button__form__company {
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 45px;
  top: 20px;
  border-radius: 100px;
  border: 1px solid #0606f9;
  color: #0606f9;
  transform: translateZ(20px);
  transition: color 0.4s;
  background-color: #fff;
  align-items: center;
}
