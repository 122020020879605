.logoSii {
  width: 21%;
}

.container__inputs__claveSii {
  padding: 10px;
  flex-direction: column;
}
.contenedor__modal__clave {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 3vh;
  padding: 0px 20px 0px 20px;
}

.header__modal__clave p {
  font-size: 20px;
  position: relative;
  top: 11px;
  left: -15px;
}

.btn__close__clave {
  border: none;
  background: none;
  color: #fff;
}
.close_sii {
  vertical-align: middle;
  /* color: #f47c03; */
  color: red;
  font-size: 1.4rem;
  margin-right: 0.5rem;
}
.input__save__clave input {
  border-radius: 0;
  border-color: gray;
}

.input__save__rut input {
  border-radius: 0;
  border-color: gray;
}

.button__save__clave {
  border: none;
  background: #002a46;
  color: #fff;
  width: 100%;
  padding: 10px;
}

.button__save__clave:hover {
  background: #eb510d;
}

.modal__clave {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  max-width: 120rem;
  width: 25%;
  padding: 0px 0px 20px 0px;
}
.header__modal__clave {
  background: #002a46;
  color: #fff;
  text-align: center;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.profile_form_container {
  width: 402px;
  height: 290px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.modal__change__password h4 {
  display: flex;
  width: 100%;
  justify-content: center;
  color: #00367b;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 48px;
  text-align: center;
  position: relative;
  margin: 0;
  top: 10px;
}

.container__tenders {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
}
.name__user {
  display: flex;
  width: 50%;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  color: #00367b;
  flex-direction: row;
  margin-top: 5%;
  padding: 5px;
  justify-content: flex-start;
}
.data__user__profile {
  display: flex;
  width: 402px;
  height: 60px;
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  justify-content: space-around;
  align-items: center;
}

.changePassword {
  width: 100%;
  position: relative;
  left: 10px;
  cursor: pointer;
  border: none;
  color: black;
  background: none;
  font-family: "Quicksand", sans-serif;
  margin-top: 30px;
  margin-left: -35%;
  font-size: 14px;
}

.changePassword:hover {
  color: #737b80;
  transition: color 0.6s;
}

.changePassword Button {
  font-size: 10px;
}

.Modal__background__changePassword {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 40;
}

.modal__change__password {
  display: flex;
  width: 450px;
  height: 380px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  flex-direction: column;
  z-index: 20;
}

.modal__change__password Button {
  border-radius: 10px;
  color: white;
}
.button__send__opinion {
  border: none;
  width: 101px;
  height: 40px;
  background: #e9f2ff;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  color: #1d4d8b;
}

.contenedor__opinions {
  display: flex;
  position: relative;
  top: -31px;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}
.container__opinion p {
  text-align: center;
}
.container__opinion {
  display: flex;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
  width: 402px;
  height: 290px;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  margin-right: 17%;
}

.input__opinion {
  width: 320px;
  height: 160px;
  padding: 10px;
  border: none;
  background: #f0f1f9;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.container__button__opinion {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
}
.MuiRating-label {
  color: #0606f9;
  font-size: 30px;
}
.profile__opinion {
  margin-top: 3%;
  margin-right: 20%;
}
.container__text__table {
  display: flex;
  flex-direction: column;
  align-items: self-start;
  justify-content: center;
  padding: 0px 18px;
  color: #a6a6a6;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
}
.container__tenders__table {
  display: flex;
  width: 100%;
  margin-top: 5%;
  flex-direction: column;
  align-items: center;
}

.tenders__table {
  display: flex;
  width: 402px;
  height: 225px;
  background: rgba(240, 241, 249, 0.57);
  border-radius: 20px;
  margin-right: 30px;
}

.tenders__table img {
  width: 32%;
}

.button__profile__cotizador {
  position: relative;
  display: flex;
  justify-content: center;
  width: 160px;
  height: 45px;
  top: 20px;
  border-radius: 100px;
  border: 1px solid #0606f9;
  color: #0606f9;
  transform: translateZ(20px);
  transition: color 0.4s;
  background-color: #fff;
}
.button__profile__cotizador::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.button__profile__cotizador:hover {
  background: #0606f9;
  transition: background 0.6s;
  color: white;
}

.button__profile__cotizador p {
  margin: auto;
  font-weight: 500;
}

.edit__form {
  color: #00367b;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
}
.container__button__profile {
  display: flex;
  width: 100%;
  /* justify-content: flex-start; */
  margin-top: 1%;
}
.container__button__changeP {
  display: flex;
  width: 100%;
  position: relative;
  top: 30px;
  left: 15px;
  font-weight: 500;
}
.profile_main_container {
  display: flex;
  align-items: center;
  margin: 0 auto;
}

.container__data p {
  position: relative;
  /* top: 25px; */
  font-size: 15px;
}

.profile__img__wanak {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  flex-direction: row;
}

.text__dataUser {
  position: relative;
  top: 25px;
}
.edit__name__user {
  cursor: pointer;
  font-size: 0.8rem;
}
.edit__name__user:hover {
  color: gray;
}

.edit__name__user img {
  width: 15%;
}
@media (max-width: 1100px) {
  .profile_main_container {
    flex-wrap: wrap;
  }
  .container__tenders {
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .profile_main_container {
    margin-right: 0;
  }
}

@media (max-width: 504px) {
  .profile_main_container {
    width: 90%;
  }
  .modal__clave {
    width: 250px;
  }
}

@media (max-width: 515px) {
  .profile_form_container {
    width: 300px;
    height: 280px;
  }
  .container__saveData {
    margin-left: 35px;
  }
  .profile_main_container {
    width: 80%;
    margin-left: 10%;
  }
  .data__user__profile {
    width: 300px;
  }
  .tenders__table {
    width: 100%;
  }
}
@media (max-width: 400px) {
  .modal__change__password h4 {
    font-size: 10px;
  }
}

@media (max-width: 370px) {
  .profile_form_container {
    width: 270px;
    height: 280px;
  }
  .profile__img__wanak img {
    width: 20%;
    position: relative;
    top: -30px;
  }
  .data__user__profile {
    width: 270px;
  }
  .profile_form_container {
    width: 240px;
  }
  .data__user__profile {
    width: 240px;
  }
  .changePassword {
    top: -22px;
  }
}

.screen__container_profile {
  width: 100%;
  display: flex;

  height: 80vh;
}
