.graphic-warranty {
  display: flex;
  width: 60%;
}
.graph-container {
  width: 100%;
  z-index: 4;
}
.text-principal {
  display: flex;
  color: #1251ae;
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: -8%;
}
.content-graphic-warranty {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.invitation__container {
  width: 90%;
  padding: 2rem 3rem;
  margin: 0 auto;
  border-radius: var(--border-radius);
  border: var(--border);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
  background: linear-gradient(180deg, #f8f9fd 0%, #eef7ff 100%);
}

.invitation__left-size {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.invitation__left-size h1 {
  color: var(--primary-color);
  font-size: 2rem;
  margin: 10px auto;
}

.invitation__left-size p {
  font-size: 1.3rem;
  color: #33363b;
  font-weight: 400;
  opacity: 0.6;
  margin: 7px auto;
  line-height: 1.9rem;
}

.invitation__right-size {
  flex: 1;
  min-width: 400px;
}

.invitation__right-size figure {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.invitation__right-size figure img {
  width: 70%;
  height: 60%;
  min-width: 250px;
  min-height: 250px;
  max-height: 400px;
  max-height: 400px;
  object-fit: contain;
}

.container__graph__title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 1006px;
  height: 144px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 50px 0px;
}
.container__text__background {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}
.text__graph {
  width: 1006px;
  height: 144px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
  border-radius: 50px 0px;
}
.text__second {
  display: flex;
  width: 40%;
  position: relative;
  left: 55%;
  top: -90px;
}
.text__second p {
  font-style: normal;
  font-weight: 200;
  font-size: 18px;
  line-height: 27px;
  color: #000000;
}

.title__graph h2 {
   position: relative;
   top: 10px;
   left: 30px;
  color: #1251ae;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
}

.warrantys__graph{
   position: relative;
background: linear-gradient(180deg, #3595D5 4.48%, #1251AE 80.21%);
box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.25);
border-radius: 0px 50px 0px 0px;
margin-right:25vw ;
top:40px;
}
.warrantys__graph p{
   text-align: center;
   color: #FBFBFC;
}

@media(max-width:1080px){
  .text__graph{
    margin-left: 20px;
    margin-right: 20px;
    width: 700px;
  }
}

@media (max-width: 999px) {
  .invitation__left-size__container {
    text-align: center;
  }
  .invitation__right-size {
    display: none;
  }
}
@media(max-width:770px){
  .text__graph{
    width: 600px;
  }
}
@media(max-width:670px){
  .text__graph{
    width: auto;
  }
  .title__graph h2 {
    width: 90%;
    font-size:26px;
  }
  .warrantys__graph{
    top:37px;
    font-size: 25px;
  }
}


@media (max-width: 450px) {
  .invitation__container {
    padding-top: 70px;
  }
  .invitation__right-size figure {
    margin-top: 40px;
  }

  .invitation__left-size h1 {
    font-size: var(--font-size-title-responsive);
  }
}


@media (max-width: 490px) {
  .graphic-warranty {
    display: none;
  }
}

@media (max-width: 911px) {
.content-graph-principal{
margin-top: -10vh;
}
}