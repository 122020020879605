.tenders__container {
  width: 100%;
  min-height: 100vh;
  margin-top: 15vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4rem;
}

.tenders__title {
  width: 88%;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.tenders__title__main {
  font-family: "Inter", sans-serif;
  color: #00367B;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 48px;
  text-align: center;
}

.tenders__title__secondary {
  margin: 10px;
  color: #33363b;
  font-weight: 400;
  opacity: 0.6;
  text-align: center;
  font-size: 1.3rem;
  width: 80%;
}

.tenders__subtitle {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 5px 20px #0000003b;
  padding: 10px 30px;
  padding-top: 15px;
  box-sizing: border-box;
}

.tenders__subtitle__main {
  color: #5c5c5c;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 10px;
  margin-top: 20px;
  text-align: center;
}

.tenders__subtitle__secondary {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #5c5c5c;
  font-size: 1.45rem;
  font-weight: 500;
  min-width: 300px;
  margin-top: 10px;
  margin-bottom: 15px;
  text-align: center;
}

.tenders__subtitle__secondary figure {
  margin: 0;
  padding: 0;
  width: 30px;
  height: 30px;
}

.tenders__subtitle__secondary figure img {
  width: 100%;
  object-fit: contain;
  margin-left: 10px;
  margin-top: -5px;
}

.tenders_table_container {
  width: 90%;
  border: var(--border);
  border-radius: var(--border-radius);
  background-color: #f8f9fd;
  padding: 20px;
  padding-bottom: 30px;
}

.tenders_table_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tenders_search_input{
  min-width: 500px;
}

.tenders_table_container h2{
  color: var(--primary-color);
}

.tenders__form {
  display: flex;
  width: 60%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 1.5rem 2rem;
  box-sizing: border-box;
  grid-gap: 2rem;
  gap: 2rem;
  background: #FFFFFF;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 25%);
  border-radius: 20px;
  margin-left: 20%;
}

.tenders__form__inputs_container {
  width: 100%;
}

.tenders__form__text {
  font-family: "Inter", sans-serif;
  color:#00367B;
  font-size: 1.3rem;
  font-weight: 600;
}

.tenders__form__inputs_container{
  width: 90%;
  display: flex;
  gap: 1em;
}

.tenders__form__inputs {
  display: flex;
  justify-content: space-between;
}

.tenders__form__button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  margin-top: 20px;
  padding-right: 6%;
}

.page_number{
  padding: 10px;
  background-color: crimson;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 99;
  
}

@media (max-width: 450px) {
  .tenders__subtitle {
    width: 370px;
  }
}

@media (max-width: 950px) {
  .tenders__form {
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: center;
  }

  .tenders__form__button {
    display: flex;
    justify-content: center;
  }

  .tenders__form__inputs_container {
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100% ;
  }

  .tenders__form__inputs_container input {
    width: 100%;
  }

  .tenders__form__inputs_container .input__container {
    display: block;
    display: flex;
    flex-direction: column;
    /* margin: 15px; */
    width: 100%;
    /* align-items: center; */
  }
  .tenders_table_container h2{
    text-align: start;
  }
}
