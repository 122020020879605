.credits-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: -10%;
}
.container-credits {
  max-width: 120rem;
  width: 75%;
  margin: 0 auto;
  margin-top: 8%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
}
.form-factoring-credits {
  width: 500px;
  background: #f8f9fd;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
  height: 35rem;
}
.capital_trabajo {
  line-height: 2.5rem;

}
.capital_trabajo h3{
    font-size: 1.5rem;
	margin-bottom: 1rem;
}
.container-form__credits {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}

.btn_global_wanak_gray {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 129px;
  height: 37px;
  border-radius: 100px;
  border: 1px solid gray;
  color: white;
  cursor: pointer;
  transform: translateZ(20px);
  transition: color 0.4s;
  background-color: gray;
}

.btn_global_wanak_gray::before {
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -5;
  width: 100%;
  border-radius: 10vmin;
}
.btn_global_wanak_gray:hover {
  background: white;
  transition: background 0.6s;
  color: gray;
}
.credits__forms{
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 1000px) {
  .container-credits {
    width: 100%;
    position: relative;
    align-items: center;
    padding: 20px;
    margin-top: 29%;
  }
}
@media (max-width: 800px) {

  .form-factoring-credits {
    width: 300px;

  }
  .credits__forms{
    width: 75%;
  }
}
