.img__credits {
  width: 80%;
}

.container__button__newsletter {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 2%;
}
.container__our__products {
  display: flex;
  width: 250px;
  height: 26rem;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 25%);
  border-radius: 20px;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.explain__cards__container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-inline: 10vw;
  width: 100%;
  gap: 50px;
  flex-wrap: wrap;
  align-items: center;
}

.container__img__our__products {
  display: flex;
  width: 210px;
  height: 200px;
  background: #f3f4fa;
  border-radius: 20px;
  flex-direction: row;
  justify-content: center;
  position: relative;
  top: -30px;
}
.text__our_products {
  color: #5579a7;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
}
.link__cotizador {
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  text-decoration-line: underline;
  color: #1251ae;
}

.container__title__our__products {
  display: flex;
  position: relative;
  top: -50px;
  background: #0b326e;
  border-radius: 4px;
  color: #fff;
  width: 200px;
  height: 40px;
  z-index: 1;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.banner__newsletter {
  width: 100%;
  margin-top: 12vh;
  background: linear-gradient(180deg, #3595d5 4.48%, #1251ae 80.21%);
  color: #fff;
  font-weight: 700;
}

.container__newSletter {
  display: flex;
  justify-content: space-around;
}

.container__newSletter .button__white a {
	text-decoration: none;
  font-weight: 500!important;
}



.explain__container {
  width: 100%;
  margin: 0 auto;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
}
.explain__hands-container {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
}

.explain__banner {
  width: fit-content;
  width: 100%;
  margin: 3rem auto;
  background-color: #f8f9fd;
  border: var(--border);
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 40px;
  text-align: center;
  margin: 6rem auto 2rem auto;
  gap: 35px;
}

.banner__right-size {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 50%;
  margin: 0 auto 0 auto;
}

.explain__input__container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 50%;
  margin: 0 auto;
}

.explain__input {
  height: 45px;
  background: #fff 0% 0% no-repeat padding-box;
  border: var(--border-input);
  border-radius: 6px;
  font-size: 1rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #00449a;
  padding-left: 15px;
  outline: none;
}

.explain__title {
  width: 100%;
  border-radius: 20px;
  margin: 50px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.explain__title p {
  color: #1251ae;
  font-style: normal;
  font-weight: 300;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
}
.title__main {
  font-weight: 600;
  font-size: 40px;
  padding: 10px;
  margin-top: -5vw;
  color: #1251ae;
  text-align: center;
}

.title__secondary {
  margin: 10px;
  color: #929293;
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
}

.explain__input__title {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0px;
  color: var(--primary-color);
  margin-bottom: 2rem;
  text-align: center;
}

/* servicios */
.box-services {
  display: block;
}

.box-services h1 {
  font-size: 2rem;
  color: var(--primary-color);
}

.box-services p {
  font-size: 1.3rem;
  font-weight: 400;
  color: #929293;
}

.box-image {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: baseline;
  margin-top: 5rem;
  margin-inline: 15rem;
}

.box-image img {
  width: 83%;
  min-width: 60%;
}

.box-image-item p {
  font-size: 1rem;
}

.box-buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-inline: 15rem;
}

.button-quote {
  height: 40px;
  background-color: #72dd6f;
  color: #ffffff;
  font-size: 0.85rem;
  font-weight: bold;
  letter-spacing: 0px;
  margin-right: 0px;
  margin-left: 18px;
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
}

.box-simulator {
  width: 100%;
  background-color: #f8f9fd;
  margin-top: -32px;
  padding: 2rem 0;
  border-left: var(--border);
  border-bottom: var(--border);
  border-right: var(--border);
  border-radius: var(--border-radius);
}

@media (max-width: 1600px) {
  .btn-suscribe {
    top: -20vh;
  }
}
@media (max-width: 1300px) {
  .container__text__newsletter {
    margin-left: 0;
  }
  .btn-suscribe {
    left: -10vh;
  }
}
@media (max-width: 800px) {
  .btn-suscribe {
    font-size: 15px;
    top: -15vh;
  }
}
@media (max-width: 700px) {
  .btn-suscribe {
    top: -12vh;
  }
  .container__img__our__products {
    display: flex;
    width: 210px;
    height: 140px;
    background: #f3f4fa;
    border-radius: 20px;
    flex-direction: row;
    justify-content: center;
    position: relative;
    top: -30px;
  }
}

@media (max-width: 650px) {
  .title__main {
    font-size: 35px;
  }
  .explain__title p {
    font-size: 15px;
    line-height: 20px;
  }
}

@media (max-width: 590px) {
  .container__text__newsletter h6 {
    display: none;
  }
  .container__button__newsletter button {
    top: -7vh;
    padding: 5px;
  }
  .container__button__newsletter {
    display: flex;
    justify-content: center;
  }
  .btn-suscribe {
    left: 0;
  }
  .container__text__newsletter h3 {
    display: flex;
    justify-content: center;
    position: relative;
    top: -5px;
  }
  .container__text__newsletter {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .title__main {
    font-size: 30px;
  }
  .explain__title {
    width: 90%;
  }
}
