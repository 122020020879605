.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 0.9375em;
  height: 0.9375em;
  border-radius: 50%;
  border-left-color: #fff !important;
  animation: spin 1s ease infinite;
  margin: 0 auto;
}

.loading_screen h1{
  font-weight: 400;
  text-align: center;
}

.loading_screen .spinner{
  width: 1.5rem;
  height: 1.5rem;
  border-color: rgb(5 17 242 / 42%) ;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
