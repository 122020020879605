.box-insurance {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.box-principal {
  display: flex;
  background-color: #f8f9fd;
  margin-top: 7%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  line-height: 5%;
  border-radius: 20px;
}

.box-principal p{
  font-size: 120%;
}

.box-text {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
  align-items: center;
  background: none;
  line-height: 100%;
}

.text-end {
  margin-top: 0px;
  font-size: 120%;
}

.box-cards {
  display: flex;
  width: 80%;
  flex-direction: row;
  align-items: flex-start;
  margin: 50px;
}

.cards {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 400px;
  align-items: center;
  background-color: #f8f9fd;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin: 3%;
  justify-content: center;
}

.cards img {
  width: 60%;
}

.cards h5 {
  display: flex;
  align-items: center;
  font-size: 20px;
}

.button-quote {
  height: 40px;
  background-color: #72dd6f;
  color: #ffffff;
  font-size: 0.85rem;
  font-weight: bold;
  letter-spacing: 0px;
  margin-right: 5px;
  margin-left: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  outline: none;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 5vh;
}

.button-quote:hover {
  background: #6dff69;
  border: #6dff69;
}


@media (max-width: 1400px) {
.box-principal {
  margin-top: 100px;
  width: 100%;
  line-height: 100%;
}

}
@media (max-width: 1400px) {
  .box-text {
    display: flex;
    /* margin-top: 100px;
    width: 100%; */
    line-height: 100%;
  text-align: center;

  }
  
  }

@media (max-width: 1700px) {
  .box-cards {
    width: 100%;
    margin-top: -5px;
  }

  .box-cards button {
    margin-top: 15px;
  }

}


@media (max-width: 1400px) {
  .box-cards button {
    margin-top: 30px;
  }
  .cards {
    width: 100%;
    height: 400px;
  }
}

@media (max-width: 1300px) {
  .box-cards button {
    margin-top: 30px;
  }
  .cards img {
  width: 60%;
}
.cards {
  width: 100%;
  height: 400px;
}
}

@media (max-width: 1200px) {
  .box-cards {
    display: flex;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }
  .cards {
    width: 30%;
  }
  .cards img {
    width: 60%;
  }
}

@media (max-width: 1100px) {
   .box-cards{
    display: flex;
    width: 80%;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

  }
  .cards {
    width: 100%;
    height: 100%;

  }
  .cards img {
    width: 50%;
  }
.cards button{

 position: relative;
 top: -10px;
}

}


@media (max-width: 1600px) {
  .box-principal {
    display: flex;
    width: 100%;
    flex-direction: column;
    line-height: 5%;
  }
  
}

@media (max-width: 300px) {
  .box-principal {
    display: flex;
    width: 100%;
    flex-direction: column;
    line-height: 5%;
  }
  
  .box-principal p{
line-height: 100%;
  }
  .box-principal h2{
    line-height: 100%;
}
}