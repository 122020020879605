
.contact__container {
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   transition: all 1s ease;
   /* margin-left: 15vw;
   margin-right: 15vw; */
}

.form-contact {
   width: 75%;
   max-width: 1200px;
   background: #ffffff;
   box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
   border-radius: 12px;
   transition: height 2s ease;
   text-align: center;
   box-sizing: border-box;
   padding: 20px 60px;
}

.form-contact__text h2 {
   color: #1251ae;
   font-style: normal;
   font-weight: 600;
   font-size: 40px;
   margin-bottom: 3rem;
   position: relative;
   top:10px;
}

.form-contact__text p {
   color: #5c5c5c;
}

.form-contact__form{
   display: flex;
   flex-direction: column;
   gap: 2rem;
}

.form-contact__inputs {
   display: grid;
   width: 100%;
   gap: 1rem;
   column-gap: 3rem;
   grid-template-columns: minmax(240px, 1fr) minmax(240px, 1fr);
}

.form-contact__optional_inputs{
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   gap: 1.5rem;
   height: fit-content;
}

.form_contact_second_title{
   font-size: 1rem;
   color: var(--secondary-color);
   margin: 0;
}

.form_contact_select{
   width: 47%;
}

.form-contact__textarea {
   flex: 1;
   width: 100%;
   height: 180px;
}

.form-contact__optional_inputs textarea {
   /* width: calc(100% - 43px); */
   width: 100%;
   min-height: 200px;
   background: #fff 0% 0% no-repeat padding-box;
   border-radius: 6px;
   font-size: 0.85rem;
   font-weight: 500;
   letter-spacing: 0px;
   color: #08254a;
   padding-left: 15px;
   outline: none;
   font-family: var(--primary-font);
   padding-top: 1rem;
   resize: none;
}

.form-contact__button {
   width: 100%;
   display: flex;
   margin-top: 15px;
   justify-content: flex-end;
}

/*  Casos */

.form-contact__file_input::-webkit-file-upload-button{
  color: #ffffff;
  padding: 7px;
  font-size: 0.8rem;
  font-weight: bold;
  letter-spacing: 0px;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background-color: var(--primary-color);
  font-family: var(--primary-font);
  cursor: pointer;
  outline: none;
}

.case_licitacionPrivada_container{
   width: 50%;
   display: flex;
   flex-direction: column;
   gap: 1rem;
}



.case__cotizacion_container{
   width: 100%;
   text-align: start;
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
}

.case__cotizacion_container__radio{
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
   gap: 12px;
}

.case__cotizacion_container__radio label{
   flex-shrink: 0;
   display: flex;
   align-items: center;

}

.case__cotizacion_container__radio input{
   margin: 0;
   margin-right: 0.3rem;
}

.incomplete_field_warning{
   color: red;
    font-size: 0.8rem;
    font-weight: 600;
    margin: 0;
    margin-top: 0.4rem;
}

@media (max-width: 900px) {
   .form-contact{
      width: 90%;
      padding: 20px 15px;
   }
}

@media (max-width: 700px) {
   /* .contact__container {
      display: none;
      background-color: tomato;
   } */
   
   .form-contact__inputs{
      display: flex;
      flex-direction: column;
   } 
   .case_licitacionPrivada_container, .form_contact_select{
      width: 100%;
   }
}
