@import 'react-notifications/lib/notifications.css';
/* /* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');


@font-face {
  font-family: Officina;
  src: url('../src/assets/fonts/OfficinaSansStd-Bold.otf');
}

:root {
  --primary-color: #0511f2;
  --secondary-color: #030a8c;
  --third-color: #0606f9;
  --details-color: #040dbf;
  --buttons-color: #50f205;
  --background-color: #fff;
  --background-faded: #0606f929;
  --border-radius: 5px;
  --box-shadow: -1px 2px 10px #d7defa;
  --border: 1px solid #d3dfff;
  --primary-font: 'Quicksand', sans-serif;;
  --secondary-font: 'Open sans', sans-serif;
  --google-font: 'Roboto', sans-serif;
  --font-size-title-responsive: 1.4rem;
  --height-input: 45px;
  --border-input: 2px solid #d3dfff;
}

footer .wave-svg {
  fill: var(--primary-color);
  margin-bottom: -0.3rem;
}

* {
  box-sizing: border-box;
}
html {
  font-size: 16px;
}

body {
  margin: 0;
  font-family: var(--primary-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--background-color);
}

code {
  font-family: 'Poppins', sans-serif;
}

.screen__container{
  width: 100%;
  margin: 0 auto;
  padding-top: 120px;
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  align-items: flex-start;


}

.screen_center{
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.screen__main_title{
  font-weight: bold;
  font-size: 2rem;
  color: var(--primary-color);
}

.screen_second__title {
  color: var(--primary-color);
  font-size: 1.3rem;
  font-weight: bold;
  margin-top: 0;
}

.notification-container {
  top: 110px !important;
  font-size: 0.9rem !important;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-button {
  width: 8px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: #f8f9fd;
  border: thin solid var(--primary-color);
  box-shadow: 0px 0px 3px #dfdfdf inset;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border: thin solid gray;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}

@media (max-width: 1400px) {
  html {
    font-size: 16px;
  }
}

@media (max-width: 700px) {
  html {
    font-size: 13px;
  }
}

@media (max-width: 1267px) {
  footer .wave-svg {
    margin-bottom: -0.4rem;
  }
}

@media (max-width: 768px) {
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-button {
    width: 8px;
    height: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f8f9fd;
    border: thin solid #00449a;
    box-shadow: 0px 0px 3px #dfdfdf inset;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #00449a;
    border: thin solid gray;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #7d7d7d;
  }
}

.notification {
	height: 140px!important;
}
.notification::before {
	margin-top: 6px!important;
}