.shoppingCart__container {
   min-height: 100vh;
   display: grid !important;
   column-gap: 5rem;
   grid-template-columns: 1fr 1fr;
}

.content_container {
   display: flex;
   width: 100%;

}
.contenedor_carrito{
   margin-top: 10rem;
   max-width: 120rem;
   width: 80%;
   padding-top: 5rem;
   margin: 0 auto;
   }

.cart_container {
   width: 100%;
   display: flex;
   max-width: 900px;
   height: -moz-fit-content;
   height: fit-content;
   padding: 3em;
   flex-direction: column;
   align-items: center;
}

.button__cotizador__shoppingCart{
width: 130px;
height: 45px;
background: #FFFFFF;
border: 1px solid #1A69DF;
box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.25);
border-radius: 10px;
color:#0606F9;
position: relative;
top: 30px;
}

.container__text__emptyCart{
   display: flex;
   position: relative;
   width: 100%;
   flex-direction: column;
   align-items: flex-start;
   position: relative;
   margin-left: 28%;
}


.container__img__emptyCart{
display: flex;
flex-direction: column;
margin-left: 50%;
}
.container__img__emptyCart img{
   width: 100%;
}

.container__emptyCart{
   display: flex;
   width:100%;
   flex-direction: column;

}

.cart_container > div:not(:last-child) {
   border-bottom: 1px solid rgba(70, 66, 66, 0.404);
   margin-bottom: 2rem;
}
.text__principal__shoppingCart{
   width: 100%;
   color:#00367B;
   font-family:'Inter', sans-serif;
   font-style: normal;
   font-weight: 600;
   font-size: 40px;
   line-height: 48px;

}


.text__second__shoppingCart{
   font-family:'Inter', sans-serif;
   font-style: normal;
   font-weight: 300;
   font-size: 18px;
   line-height: 29px;
}
/* ////////////////////////////////// */

/* Cart Total */

.total_price_container {
   border: var(--border);
   border-radius: 5px;
   min-width: 200px;
   flex-grow: 1;
   max-width: 450px;
   margin: 0;
   padding: 2.2em 20px;
   display: flex;
   flex-direction: column;
   gap: 1.5rem;
   height: fit-content
   /* align-self: end; */
}

.total_price_container *{
   margin: 0;
}

.total_price_container h1{
   font-size: 1.5rem;
}

.total_price_info {
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   width: 100% !important;
}

.total_price_info button {
   align-self: end;
}

.total_price_info h1 {
   font-size: 1.5em !important;
   font-weight: 400;
}

.total_price_item{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: space-between;

}
.total_price_item *{
   margin: 0;
}

.total_price_item p{
   font-size: 1em;
   color: #2c2a2ab8;
   
}

.total_price_item span{
   font-size: 1em;
   color: var(--secondary-color);
   font-weight: 500;
}
.total_price_item .total{
   font-size: 22px;
   font-weight: 600;
   margin-top: 1.2rem;
}

.coupon_input_container{
   border-radius: var(--border-radius);
   background-color: #f8f9fd;
   padding: 1rem;
   display: flex;
   align-items: center;
   gap: 1rem;
}

.coupon_input_container p{
   width: 80%;
   font-weight: 500 !important;
}

.coupon_input_button{
   border-radius: 50%;
   width: 30px;
   height: 30px;
   padding: 5px;
   display: flex;
   align-items: center;
   justify-content: center;
   cursor: pointer;
   color: var(--secondary-color)
}

.coupon_input_button:hover{
   background-color: #c8cbd3;
}

.terms_input_container{
   color: black !important;
   display: flex;
   align-items: center;
   gap: 0.5rem;

}

.terms_input_container label span {
   color: var(--primary-color);
   cursor: pointer;
 }
 
 .terms_input_container input {
   width: 8%;
 }

 .terms_input_container label span:hover {
   border-bottom: var(--primary-color) 1px solid;
 }
 

.total_price_pay_btn{
   color: white;
   margin: 0;
   font-weight : 500 !important;
   background-color: var(--primary-color);
   width: 100%;
   font-size: 1.2rem;
}

/* ////////////////////////////////// */

.terms_modal_container .modal_container{
   padding: 2rem !important;
}

.terms_container{
   margin-top: 1rem;
   margin-right: 1rem;
   padding: 1rem;
   background-color: #f8f9fd;

}

@media (max-width: 1200px) {
   /* .product_container {
    font-size: 14px;
  } */
  .container__text__emptyCart{
   margin-left: 0;
  }
   .product_info_container {
      font-size: 12px;
      width: 80%;
   }

   .product_info_container p {
      font-size: 1.2em;
   }
}

@media (max-width: 950px) {
   .product_info_container h1 {
      font-size: 2em;
   }

   .product_info_container .shrink {
      height: 1.5em;
      overflow-y: hidden;
   }

   .cart_container {
      padding: 1.5rem;
   }
   /* /* .product_container {
    flex-direction: column;
  }

  .product_info_container {
    width: 100%;
  } */
   .product_price_container h1 {
      font-size: 1.2em;
   }
}

@media (max-width: 750px) {
   /* .product_price_container {
    margin-right: 1em;
  } */

  .total_price_item{
     font-size: 14px;
  }
   .content_container {
      flex-direction: column;
   }

   .content_container {
      width: 100%;
   }
   .cart_container {
      margin-right: 0;
      width: 100%;
   }

   .total_price_container{
      width: 100%;
      max-width: 100000px;
   }
}
@media (max-width: 500px) {
   .container__img__emptyCart{
      display: none;
     }

     .shoppingCart__container {
      grid-template-columns: 1fr;
     }
   
   }
