.title__checkWarranty{
  text-align: center;

}
.checkWarranty__container {
  width: 100%;
  /* height: 100vh; */
  /* margin-top: 10vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkWarranty__modal {
  width: 860px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  box-sizing: border-box;
  margin: 10rem 5rem 5rem 5rem;
  padding-top: 40px;
  padding-bottom: 35px;
  position: relative;
  background-color: #e9f2ff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.checkWarranty__modal h1 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 42px;
  color: #00367b;
}

.checkWarranty__image {
  width: 100%;
  border-bottom-right-radius: 200px;
  border-top-right-radius: 200px;
  border-top-left-radius: 180px;
  border-bottom-left-radius: 180px;
  /* height: 50px; */
  background: #fbfbfc;
}

.checkWarranty__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.checkWarranty__input__container {
  width: 100%;
  /* height: 60px; */
  display: flex;
  /* background-color: rgb(0, 101, 255, 0.08); */
  margin-bottom: 1rem;
  /* flex-wrap: wrap; */
  align-content: center;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
}

.checkWarranty__input__title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #000000;
}

.checkWarranty__input {
  width: 65%;
  height: var(--height-input);
  /* background: #ffffff 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 3px 6px #00000029; */
  border: var(--border-input);
  border-radius: var(--border-radius);
  font-size: 0.85rem;
  font-weight: normal;
  letter-spacing: 0px;
  color: #908e8e;
  padding-left: 15px;
  outline: none;
}

.line {
  display: none;
  border-top: 1px solid #0065ff;
  position: absolute;
  right: 120px;
  top: 160px;
  width: 70px;
  padding: 0;
  margin: 20px auto 0 auto;
  transform: rotate(90deg);
}

.arrow-down {
  display: none;
  position: absolute;
  right: 148px;
  top: 215px;
  width: 0px;
  height: 0px;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 7px solid #0065ff;
  font-size: 0px;
  line-height: 0px;
}


.container__button__checkWarranty button {
  width: 226px;
  height: 48px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
