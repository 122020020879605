.home__container {
   width: 100%;
   background-color: #e7f3fe;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   background-color: #fff;   
   gap: 7rem;
}
.container__text__newsletter {
   position: relative;
   width: 100%;
   padding: 20px;
   color: #fff;
   font-weight: 700;
   font-family:unset!important ;
   font-style: italic;
   display: flex;
   flex-direction: column;
   margin-left: 10vh;
 }