.blog__container {
width: 100%;
display: flex;
flex-direction: row;
justify-content: center;
}

.blog__cards__container{
  display: flex;
  width: 70%;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  }

.background__cards {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30vw;
  height: 70vh;
  background: rgba(240, 241, 249, 0.8);
  border-radius: 50px;
}
.blog__cards__container{
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  gap: 50px;
  position: relative;
                        
}
.explain__container__blog{
  width: 100%;
  margin: 0 auto;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  position: relative;
  top: -12vh;
}
