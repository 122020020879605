.input__container{
  width: 100%;
}

.input__title{
  font-size: .92em;
  font-weight: 600;
  letter-spacing: 0;
  color: #666363;
  margin: 0;
  margin-bottom: -0.1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
  margin-top:17px;
  margin-left: 8px;
  color:#999999;
}


 input, .input__container select{
  width: 100%;
  height: 40px;
  background: #fff 0 0 no-repeat padding-box;
  border: var(--border-input);
  border-color: #bbbaba;
  border-radius: 10px;
  font-size: .85em;
  font-weight: 500;
  letter-spacing: 0;
  color: #08254a;
  padding: 0 0.5rem;
  outline: none;
  font-family: "Montserrat";
}

.input_text_container{
  position: relative;
  width: 100%;
}

.input_error{
  animation-name: error;
  animation-duration: 0.3s;
}

.input_error .input__title {
  color: crimson;
}

.input_error input, .input_error select, .input_error { 
  border-color: #ff000061 !important;
  color: #ab2c2cbf !important;
}

.input_error input::placeholder, .input_error select::placeholder, .input_error::placeholder{
  color: #ff000075 !important;
}

.rut_input_container{
  position: relative;
}

.invalid_rut{
  color: red;
  font-size: 12px;
  font-weight: 500;
  position: absolute;
  top: 60px;
  right: 4px
}

.input_icon_container{
  position: absolute;
  top: 5px;
  right: 15px;
  cursor: pointer;
}

/* .input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
} */

/* Firefox */
/* .input[type='number'] {
  -moz-appearance: textfield;
} */

@keyframes error {
  0% {transform: translateX(0);}
  40% {transform: translateX(5px);}
  70% {transform: translateX(-5px);}
  100% {transform: translateX(0);}
}

