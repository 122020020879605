.tenders_table_container {
  width: 90%;
  border-radius: 20px;
  background: #e9f2ff;
  padding: 20px 20px 30px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
}


.tenders_table_header{
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tenders_table_header h2{
  color: #00367B;
  font-family: "Inter", sans-serif;
  font-style: normal;
  text-align: center;
}

.tenders_search_input{
  min-width: 400px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  gap: 0.8rem;
  margin-right: 0.5rem;

}

.tenders_search_icon{
  font-size: 21px;
  color: var(--secondary-color);
  cursor: pointer;
  position: relative;
top: -8px;
}

.tenders_search_icon:hover{
  color: var(--primary-color);
}
  
.MTableToolbar-root-75, .MTableToolbar-root-5, .MTableToolbar-root-89, .MTableToolbar-root-19 .jss5{
  display: none !important;
}


.input__table__container{
  position: relative;
  top:-18px;
  margin-top: 5%;
  width: 100%;
}


@media (max-width: 950px){
  .tenders_table_header{
    flex-direction: column;
    align-items: flex-start;
  }

  .tenders_search_input{
    width: 100%;
    min-width: 1px;
    margin-bottom: 1.5rem;
  }
}