.principal-warrantys{
    width: 100%;
    display: flex;
    margin-top: 7%;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
}

.container__text__warrantys{
    text-align: center;
    margin-top: 10vh;
}
.form-warrantys{
    width: 400px;
    background: #F8F9FD;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    transition: height 2s ease;
    text-align: center;
    box-sizing: border-box;
    padding: 20px;
}
.container__text__warrantys h5{
color: #00367B;
font-style: normal;
font-weight: 700;
font-size: 30px;
line-height: 50px;
text-align: center;
}

/* .principal-warrantys p{
    color: #00367B;
    font-family: 'Inter', sans-serif;
font-weight: 700;
font-size: 30px;
line-height: 50px;
text-align: center;
} */
/* .principal-warrantys h3{
    color: blue;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 50px;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: center;
    } */