.principal-security{
  width: 100%;
  display: flex;
}

.form-contact__optional_inputs{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.container-cards{
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-top: -10%;
}
.container-cards h5{
  text-align: center;
  color: #38a1ff;
}
.container-cards p{
  text-align: center;
}
.security-container {
  display: flex;
  align-content: center;
  justify-content: space-evenly;
  margin-top: 3%;
  align-items: center;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 10%;
}

.form-security {
  width: 400px;
  background: #F8F9FD;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;

}
.form-security__inputs {
  display: flex;
  width: 100%;
  flex-direction: column;

}

.form-contact-button {
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-end;
}

.form-contact-button button {
  font-size: 15px;
}

.banner-text {
  display: flex;
  background-color: #38a1ff;
  margin-top: 7%;
  width: 100%;
  flex-direction: column;
  align-items: center;
  line-height: 100%;
  border-radius: 10px;
}

.banner-text h1 {
  color: #fff;
}

.box-text-security {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
  align-items: center;
  background: none;
  line-height: 5%;
  font-size: 20px;
}

.cards-info-movilidad {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  flex-wrap: wrap;
}

.info-contact {
  margin-top: -15px;
  text-align: center;
  line-height: 5px;
}
.info-contact h5{
color: #00367B;
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 50px;
text-align: center;
} 

.info-contact p{
  font-style: normal;
font-weight: 400;
font-size: 15px;
line-height: 25px;
text-align: center;
}

.form-security__form {
  display: flex;
  flex-direction: column;
  grid-gap: 2rem;
  gap: 2rem;
  margin-top: 25px;
}

.container-products-for {
  display: flex;
  width: 50%;
  flex-direction: column;
}
.container-products-for p {
  display: flex;
  width: 50%;
  margin-top: 40px;
  justify-content: space-around;
  font-size: 30px;
  flex-direction: row;
  position: relative;
  top: 110px;
}

.button-quote-form {
  height: 40px;
  background: linear-gradient(180deg, #0099FF 0%, #0606F9 100%);
border-radius: 10px;
border: none;
  color: #ffffff;
  letter-spacing: 0px;
  margin-right: 5px;
  margin-left: 5px;
  outline: none;
  cursor: pointer;
  padding-left: 30px;
  padding-right: 30px;
  margin-top: 3vh;
  font-family: 'Inter' sans-serif;
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 24px;
text-align: center;
}

.cards__mobility {
  width: 200px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
}
.cards__mobility h3{
  font-size: 20px;
  text-align: center;
}

.container__img__mobility{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40%;

}


.text__mobility{
margin-top: 30%;
font-family: 'Inter' sans-serif;
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 17px;
text-align: center;
}
.text__mobility p{
margin-top: -55%;
width: 100%;
font-family: 'Inter' sans-serif;
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 17px;
text-align: center;
}

@media (max-width: 300px) {
  .form-contact-button {
    display: flex;
    flex-direction: column;
  }
  .form-contact-button button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

}

@media (max-width: 1280px) {
  .container-cards{
margin-top: 20px;
  }
}
@media (max-width: 1000px) {
  .container-cards{
width: 70%;
  }
  .security-container{
    width: 50%;
    position: relative;
    margin-left: 20px;
    align-items: center;
  }
  .principal-security{
  display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media (max-width: 500px) {
  .banner-text h1 {
    font-size: 20px;
  }
}

@media (max-width: 1200px) {
  .cards-info-movilidad {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}

@media (max-width: 1000px) {
  .cards-info-movilidad {
    display: none;
  }
  .container-products-for p {
    display: none;
  }
}

@media (max-width: 750px) {
  .cards-info-movilidad img {
    display: none;
  }
  .container-products-for p {
    display: none;
  }
  .info-contact h4 {
    line-height: 100%;
  }

  .security-container {
    margin-top: 20%;
  }
  .form-security {
    margin-top: 8px;
  }

  .form-contact-button {
    margin-top: 10px;
  }
}
@media (max-width: 560px){
  .info-contact h5{
line-height: 20px;
  }
  .info-contact p{
display: none;
  }
}
