.form__buttons {
  display: flex;
  gap: 1rem;
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 10%;
}

.form__buttons button {
  margin: 0;
}

/* .btn-submit :enabled{
background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%); 
} */

.form_bottom_text {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 20px;
  font-size: 1.1rem;
  font-weight: 600;
  color: #9f9f9f;
  cursor: pointer;
  text-decoration: none;
}

.form__footer__text:hover {
  text-decoration: underline;
}

.form__footer__button {
  padding-left: 10px;
  padding-right: 10px;
  height: 50px;
  width: 210px;
  font-size: 0.9rem;
  font-weight: 900;
  color: #304f97;
  background-color: #fff;
  border: 1px solid #304f97;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  cursor: pointer;
  margin-bottom: 3rem;
}

.p-google {
  color: #304f97;
  width: 100%;
  text-align: center;
}

.p-google hr {
  width: 100%;
}

.btn-google {
  background: none;
}
