.validateEmail_container{
  /* width: 651px;
  height: 420px; */
  background: #FFFFFF;
  box-shadow: 0px 5px 20px rgb(0 0 0 / 25%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -15vh;
  padding: 30px  100px;

}

 .validate__body {
  display: flex;
  grid-gap: 1rem;
  gap: 1rem;
  flex-direction: column;
  }
  
  .validate__title {
    display: flex;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    color: #1251AE;
  }
  
 .validate__image {
   display: flex;
   align-items: center;
   justify-content: center;
   margin-top: -8%;

  }

  .text-final-validate-email {
    margin-top: 10px;
  }
  
  .validate__image img {
    width: 249px;
    Height:231px;
  }

  .text-validate-email {
    text-align: center;
  }

  .btn-send-validate-email {
    background-color: #1251AE;
    border-radius: 10px;
  }
  
  .screen__principal__validate {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .stick-wizard-step3 {
      display: flex;
      background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
      width: 630px;
      height: 16px;
      border-radius: 30px;
      transition: width 2s;
  }
  .wizzard-accountCreated {
          display:flex;
          background-color: none;
          width: 650px;
          height: 16px;
          border-radius: 30px;
          flex-direction: row-reverse;
        }
        
  .container__validate__screen {
    height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }

    

  @media (max-width: 750px){
    .validateEmail_container{
      min-width: 300px;
      width: 90%;
    }
    .validate__body{
      flex-direction: column;
      
    }
    .validate__image img {
      width: 80%;

    }
  }