.screen__document__title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.8rem;
  color: #00367b;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.documents-screen {
  gap: 1rem;
}

.documents-screen__title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.8rem;
  color: #00367b;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.documents-screen__list {
  margin: 0;
  padding: 0;
  width: 100%;
}

.documents-screen__list li {
  list-style: none;
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  color: #00367b;
  font-size: 1.3em;
  line-height: 1.4;
}

.documents-screen__list li svg {
  font-size: 1.4em;
}

.documents-screen__span--bold {
  font-weight: bold;
  margin-left: 4px;
}

.documents-screen__table {
  width: 90%;
  margin-top: 30px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  padding: 20px;
  padding-bottom: 30px;
}

.documents-screen__table__title {
  color: #00367b;
  text-align: center;
  font-size: 1.5rem;
  margin-bottom: 26px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
}

.companySelector_container {
  /* align-self: flex-start; */
  margin-top: 2rem;
  margin-left: 0%;
  width: 100%;
}
.companySelector_container select {
  width: 50%;
}
.container__documents__all {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 120rem;
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 500px) {
  .documents-screen__list {
    font-size: 12px;
  }
  .companySelector_container {
    width: 100%;
  }
}

/* changes */
