.timeline_container{
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin: 30px 0;

}

.timeline_line{
    height: 95%;
    width: 6px;
    position: absolute;
    background-color: var(--primary-color);
    left: 50%;
    z-index: 99;
    border-radius: 5px;
    top: 12px;
}

.timeline_item{
    /* background-color: aquamarine; */
    width: 50%;
    display: flex;
    padding: 10px 60px;
    position: relative;

}

.right{
    align-self: flex-end;
}

.timeline_info{
    max-width: 500px;
    background-color: white;
    border-radius: 10px;
    box-shadow: var(--box-shadow);
    margin-left: auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.timeline_info h1{
    margin: 0;
    font-size: 1.2em;
    font-weight: 600;
    color:#00367B;
   font-style: normal;
}

.timeline_info img{
    width: 100%;
    border-radius: 5px;
}

.right .timeline_info{
    margin: 0;
    margin-right: auto;
}

.timeline_year{
    background-color: white;
    width: 70px;
    height: 70px;
    border: 5px solid var(--secondary-color);
    border-radius: 50%;
    position: absolute;
    right: 0;
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(53%);
}


.right .timeline_year{
    transform: translate(-47%);
    left: 0;
}

.timeline_year span{
    color: var(--secondary-color);
    font-weight: 800;
    font-size: 1.4em;
}

@media(max-width: 1000px){
    .timeline_info h1{
        font-size: 1.1em;
    }
}

@media(max-width: 700px){

    .aboutUs_timeline_container{
        padding: 0 30px;
    }
    
    .timeline_line{
        right: 0 ;
        left: auto;
    }

    .timeline_item{
        width: 100%;
        padding-left: 0;
    }

    .timeline_year{
        transform: translate(43%) !important;
    }

    .right .timeline_info{
        margin: 0;
        margin-left: auto;
    }

    .right .timeline_year{
        left: auto;
        right: 0;
    }

    .timeline_info h1{
        font-size: 1em;
    }
}