.img__executive {
  display: flex;
  width: 100%;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: flex-start;
  margin: 0 40px;
  position: relative;
  top: -37%;
}

.container__executive .container__image__myexecutive {
  width: 100%;
  display: flex;
  justify-content: center;
}
.screen__container__executive {
  height: 50vh;
  max-width: 120rem;
  width: 90%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container__image__myexecutive img {
  width: 35%;
}
.myexecutive {
  /* margin-top: 15%; */
  width: 300px;
  height: 321px;
  background: #ffffff;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.container__executive {
  display: flex;
  background: #f0f1f9;
  border-radius: 10px 10px 0px 0px;
  width: 300px;
  height: 53px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.info__executive {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  margin-left: 0;
  padding: 0;
}
.info__executive img {
  padding-right: 10px;
}
.text__executive {
  padding: 20px;
  position: relative;
  top: -70px;
  text-align: center;
}

.message {
  position: relative;
  top: 20px;
  left: -2px;
}
.phone {
  position: relative;
  top: 40px;
}
.text__final {
  color: #00367b;
  position: relative;
  top: -30px;
  left: 45px;
  cursor: pointer;
}

.container__company__selector {
  width: 100%;
}
.container__company__selector select {
  width: 250px;
  background-color: #f0f1f9;
  border: none;
}
@media (max-width: 700px) {
  .myexecutive {
    width: 250px;
    height: 290px;
  }
  .container__executive {
    width: 250px;
  }
}
.not__company {
  font-size: 30px;
  font-family: 'Inter', sans-serif;
  text-align: center;
  margin-left: 0;
  color: #00367B;
  display: flex;
  align-items: center;
}
