.text__principal__notification{
display: flex;
width: 100%;
justify-content: center;
color: #00367B;
font-style: normal;
font-weight: 600;
font-size: 30px;
line-height: 48px;
text-align: center;

}
.container__image__notifications{
    width: 100%;
    display: flex;
    justify-content: center;
}
.container__image__notifications img{
    width: 15%;
}