.paymentModal_container{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;
  padding: 1rem 0.5rem;
}

.paymentModal_container h1{
  margin: 0;
  font-size: 1.5rem;
  font-weight: 400;
}

.payment_method_container{
  height: 100px;
  width: 100%;
  box-shadow: -1px 2px 10px #cccfda;
  border-radius: var(--border-radius);
  padding: 1rem;
  cursor: pointer;
}

.payment_method_container:hover{
  box-shadow:0 6px 24px -4px rgb(168 168 168 / 50%)
}

.payment_method_container img{
  border-radius: var(--border-radius);
  width: 100%;
  height: 100%;
}

.loading_payment_modal{
  height: 300px !important;
}
