.header__infoReceived{
  display: flex;
  width: 100%;
  margin-top: 10vh;
  color: #00367B;
  font-style: normal;
font-weight: 700;
font-size: 40px;
line-height: 50px;
justify-content: center;
text-align: center;
}

.background-img {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;

}

.button-back-container{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
}
  
.box-text-infoReceived{
  display: flex;
  flex-direction: column;
  align-items: center;
  background: none;
  line-height: 5%;
  font-size: 20px;
  text-align: center;
}
.box-text-infoReceived p{
  line-height: 20px;
}

.container__infoReceived{
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 5vh;
}

  @media (max-width: 700px) {
    .background-img img{
        width: 50%;
        height: 50%;

    }
  }