
.forgotPassword-form__container{
    display: flex;
    justify-content: center;
}
.form__forgot__password{
    background: #FFFFFF;
    border: var(--border);
    border-radius: 20px;
    position: relative;
    padding: 2rem 3rem;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.25);
}

.email__forgotPassword{
    width: 80%;
}
.forgotPassword__title{
    display: flex;
    justify-content: center;
    color:#00367B; 
    font-family: 'Quicksand';
    text-align: center;

}

.btn__send__forgot__password{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-top: 30px;
}

.Modal__Forgot__Password {
    width: 100px;
    height: 100px;
    background-color: aqua;
}
.forgot__password__screen__container{
    width: 90%;
    height: 600px;
    margin: 0 auto;
    margin-top: 12vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color);
}

.swal-button{
    background-color: var(--third-color);
}