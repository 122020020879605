
.container__login{
   width: 100%;
}
.form__container {
   width: 450px;
   height: 640px;
   display: flex;
   background: #ffffff;
   border-radius: 20px;
   position: relative;
   padding: 2rem 3rem;
   box-shadow: 0px 0px 15px rgb(0 0 0 / 25%);
   flex-direction: column;
   justify-content: center;
   align-items: center;

 }
.rocket-login {
   display: flex;
   width: 70%;
   margin-bottom: -60%;
   position: relative;
   top:-110px;
   left:30px;
}


.login-screen__container{
   width: 90%;
   margin: 0 auto;
   display: flex;
   justify-content: center;
   align-items: center;
   background-color: var(--background-color);
padding-top: 20vh;
}

.login-form__container{
   width: 100%;
   display: flex;
   flex-direction: column;
   align-items: center;
   gap: -1rem;
   margin-top: 20%;
}

.login__logo {
   height: 10vh;
   width: 200px;
   background-image: url("../../assets/images/logo.png");
   background-repeat: no-repeat;
   background-position: left;
   background-size: contain;
   cursor: pointer;
   margin-top: 30px;
}

.login__title {
   font-size: 1.8rem;
   font-weight: 700;
   color: var(--primary-color);
   letter-spacing: 0px;
   margin: 1rem 0 2rem 0;
}

.login__forgot-password {
   font-size: 14px;
   font-weight: 500;
   color:#a59a9a;
   cursor: pointer;
   position: relative;
   top: 4px;
   align-self: end;
   border: none;
   background: none;
}

.login__forgot-password:hover {
   text-decoration: underline;
   color: var(--secondary-color)
}

.btn-ingresar-login {
   color: #ffffff;
}


.text__user__password__incorrect{
   color: red;
}


@media (max-width: 450px) {
   .login__container {
      height: auto;
      margin-bottom: 5vh;
   }
   .rocket-login {
    top:-120px;
   }
}

/* @media (max-width: 1700px) {
   .login-screen__container {
      margin-top: 15%;
   }

} */

/* @media (max-width: 1200px) {
   .form__container {
      margin-top: 120px;
   }
} 

@media (max-width: 900px) {
   .form__container {
      margin-top: 120px;
   }

}

@media (max-width: 450px) {
   .form__container {
      margin-top: 250px;
   }
}  */