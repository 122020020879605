.simulation__form__button {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  margin-top: 20px;
  padding-right: 6%;
  margin-left: 3.1vw;
}
.simulation__form__button button {
  width: 110px;
  height: 35px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: none;
  color: #fff;
}
.cards_warranty {
  width: 250px;
  height: 28rem;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 0px 4px rgb(0 0 0 / 25%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cards_warranty h5{
  text-align: center;
  font-size: 26px;
}
.box__cards__warranty {
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.container__text__warrany {
  margin-top: 2rem;
	height: 10rem;
  width: 100%;
	text-align: center;
}
@media (max-width: 350px) {
  .simulation__form__button {
    display: flex;
    justify-content: center;
  }
}
.container__simulation__form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 1s ease;
  padding-left: 10px;
  padding-right: 10px;
}
.simulation__form {
  max-width: 1200px;
  background: #ffffff;
  box-shadow: 0px 4px 15px rgb(0 0 0 / 25%);
  border-radius: 12px;
  transition: height 2s ease;
  text-align: center;
  box-sizing: border-box;
  padding: 20px 60px;
}
.container__data__executive strong {
  color: #0606f9;
}

.container__data__executive {
  display: flex;
  flex-direction: column;
}
.container__button__modalErrorExe {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.btn__modalError {
  border: none;
  background: blue;
  color: #fff;
  display: flex;
  margin-top: 20px;
  border-radius: 10px;
}

.accept-section {
  padding: 5%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.accept-section > input {
  width: 1.5em !important;
}
.accept-section > span > strong {
  color: #0606f9;
}

.acept-check strong {
  color: #0606f9;
  position: relative;
  left: 5px;
}

.checkbox input {
  width: 10%;
}
.btn__checkbox {
  width: 110px;
  height: 35px;
  color: #fff;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: none;
  color: #fff;
}

.btn__checkbox:disabled {
  background: linear-gradient(180deg, #7f8a92 0%, #6a6a7a 100%);
}

.summary__text {
  color: #00367b;
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 50px;
  text-align: center;
}
.container__button__checkbox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.contenedor__modal__summary input {
  border-left: none;
  border-right: none;
  border-top: none;
  border-radius: 0;
  border-bottom: 1px solid #e5e5e5;
  background: none;
  width: 300px;
  height: 50px;
}
.contenedor__modal__summary {
  padding: 50px;
}

.tenders__form__button button {
  width: 110px;
  height: 35px;
  background: linear-gradient(180deg, #0099ff 0%, #0606f9 100%);
  box-shadow: 0px 3px 3px rgb(0 0 0 / 25%);
  border-radius: 10px;
  border: none;
  color: #fff;
}
.tenders__form__button {
  margin-left: 40px;
}

.modal__simulation__summary {
  margin-top: 40px;
  width: 430px;
  background: #f8f9fd;
  border: 1px solid #9f9f9f;
  border-radius: 20px;
}

.container__modal__simulation {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
}
.container__modal__summary {
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 40px;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
  overflow-y: scroll;
}
.container__companies {
  margin-left: -10px;
  width: 50%;
}

.modal__simulation {
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  width: 510px;
  padding: 20px;
}
.btn__close {
  width: 15%;
  border: none;
  background-color: blue;
  border-radius: 10px 10px 10px 10px;
  color: #ffffff;
}

.container__button__modalError {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.contenedor__modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* height: 250px; */
  justify-content: flex-end;
}
.contenedor__modal img {
  width: 15%;
  position: relative;
  top: -1vh;
}

.simulation__form__inputs-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.container__principal {
  margin-top: -20vh;
}

.container__views__warrantys {
  width: 100%;
}

.tenders__form__inputs__simulation {
  width: 90%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-direction: row;
}
.tenders__form__inputs__simulation2 {
  width: 90%;
  display: flex;
  grid-gap: 1em;
  gap: 1em;
  flex-direction: row;
}
.text__alert {
  color: red;
  font-size: 20px;
  position: relative;
  top: -20px;
}

.warranty__fields {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.warranty__fields__flex1 {
  display: flex;
  width: 100%;
  gap: 1em;
  justify-content: center;
}

.warranty__fields__flex2 {
  display: flex;
  width: 100%;
  gap: 2em;
}
.warranty__fields__flex2 select {
  width: 100%;
}
.container__auto {
  display: flex;
  width: 100%;
  margin-top: 20px;
  flex-direction: row;
  justify-content: center;
  gap: 0.5rem;
}

.container__auto strong {
  color: blue;
  cursor: pointer;
}

.container__inputs__simulation {
  display: flex;
  width: 100%;
}

.container__search {
  display: flex;
  width: 100%;
  margin-top: 5%;
  flex-direction: column;
  justify-content: center;
  line-height: 25px;
  align-items: center;
  font-weight: 400;

  color: #00367b;
}

@media (max-width: 1100px) {
  .container__principal {
    margin-top: 0;
  }
}

@media (max-width: 610px) {
  .warranty__fields {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

@media (max-width: 700px) {
  .warranty__fields__flex1 {
    display: flex;
    flex-direction: column;
  }
  .warranty__fields__flex2 {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
  }
}
